import React, { useContext } from 'react';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import ImgKr from '../../static/images/experience/experience-kr.png';
import ImgEn from '../../static/images/experience/experience-en.png';

const Experience = (props) => {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);

  return (
    <section className='experience' lang={language}>
      <div className='container no-gutters'>
        <div className='row justify-content-start no-gutters'>
          <article className='col-lg-12 order-2 order-lg-1 text-wrapper p-0'>
            <div className='text-center text-lg-center'>
              <h1>{t('SECTION1_HEADING')}</h1>
              <p>{t('SECTION1_BODY')}</p>
            </div>
          </article>
          <article className='order-1 order-lg-2 image-wrapper row justify-content-center text-center'>
            <img
              alt={t('IMG_ALT_EXPERIENCE')}
              src={language === 'en' ? ImgEn : ImgKr}
            />
          </article>
        </div>
      </div>
    </section>
  );
};

export default Experience;
