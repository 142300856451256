import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

const MenuMobile = (props) => {
  const { changeLanguage } = useI18next();

  const data = useStaticQuery(graphql`
    query MainMobileMenuQuery {
      allMainMenuJson {
        edges {
          node {
            name
            url
            weight
          }
        }
      }
    }
  `);

  return (
    <div
      id='main-menu-mobile'
      className={`main-menu-mobile ${props.active ? 'open' : ''}`}
    >
      <ul>
        {data.allMainMenuJson.edges.map(({ node }) => (
          <li key={node.name}>
            <Link to={node.url} activeClassName='active'>
              {node.name}
            </Link>
          </li>
        ))}
        <div className='language-select-mobile'>
          <li>
            <a
              href='#!'
              aria-label='change-to-english'
              tabIndex={0}
              role='button'
              onClick={() => changeLanguage('en')}
            >
              EN
            </a>
          </li>
          <li>
            <a
              href='#!'
              aria-label='change-to-english'
              tabIndex={0}
              role='button'
              onClick={() => changeLanguage('kr')}
            >
              한국어
            </a>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default MenuMobile;
