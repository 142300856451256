import React, { useContext } from 'react';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import ImgKr from '../../static/images/automation/automation-kr.png';
import ImgEn from '../../static/images/automation/automation-en.png';

import ImgKrMobile from '../../static/images/automation/automation-kr-mobile.png';
import ImgEnMobile from '../../static/images/automation/automation-en-mobile.png';

const Automation = (props) => {
  const { t } = useTranslation();

  const { language } = useContext(I18nextContext);

  return (
    <section className='automation'>
      <div className='container no-gutters'>
        <div className='row justify-content-start w-100 inner-container'>
          <article className='col-12 col-lg-6 order-2 order-lg-2 text-wrapper p-0'>
            <div className='text-center text-lg-left align-self-center'>
              <h1>{t('SECTION3_HEADING')}</h1>
              <p>{t('SECTION3_BODY')}</p>
            </div>
          </article>
          <article className='col-12 col-lg-6 order-1 order-lg-1 p-0 img-wrapper'>
            <img
              alt={t('IMG_ALT_AUTOMATION')}
              src={language === 'en' ? ImgEn : ImgKr}
            />
          </article>
          <article className='col-12 col-lg-6 order-1 order-lg-1 p-0 img-wrapper-mobile'>
            <img
              alt={t('IMG_ALT_AUTOMATION')}
              src={language === 'en' ? ImgEnMobile : ImgKrMobile}
            />
          </article>
        </div>
      </div>
    </section>
  );
};

export default Automation;
