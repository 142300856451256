import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Intro from '../components/Intro';
import Header from '../components/Header';

import Experience from '../components/Experience'; // section 1
import AllInOne from '../components/AllInOne'; // section 2
import Automation from '../components/Automation'; // section 3
import Assistance from '../components/Assistance'; // section 4
import VirtualStore from '../components/VirtualStore'; // section 5
import ContactUs from '../components/ContactUs';

const Home = (props) => {
  const intro = props.data.intro;

  const { t } = useTranslation();

  return (
    <Layout bodyClass='page-home'>
      <SEO title={t('TITLE')} />
      <Helmet>
        <meta
          name='description'
          lang='en'
          content='Bring the physical world into the metaverse through casual games'
        />
        <meta
          name='description'
          lang='ko'
          content='메타버스 세상에서 펼쳐지는 새로운 고객 경험, 바로 시작하세요'
        />
        <script
          src='https://kit.fontawesome.com/24c9efb239.js'
          crossorigin='anonymous'
        />
      </Helmet>

      <Header />
      <Intro intro={intro} />

      <div id='product' />
      <Experience />
      <AllInOne />
      <Automation />
      <Assistance />
      <VirtualStore />
      <div id='news' />
      <div id='contact' />
      <ContactUs />
    </Layout>
  );
};

export const query = graphql`
  query {
    intro: markdownRemark(fileAbsolutePath: { regex: "/content/index.md/" }) {
      html
      frontmatter {
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
