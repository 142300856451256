import React, { useContext } from 'react';
import {
  I18nextContext,
  Trans,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import Social from './Social';
import ContactUsForm from './ContactUsForm';
import Envelope from '../../static/images/contact-us/envelope.svg';
import Phone from '../../static/images/contact-us/phone.svg';
import Map from '../../static/images/contact-us/map.svg';

const ContactUs = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query ContactQuery {
      allContactJson {
        edges {
          node {
            name
            value
            icon
          }
        }
      }
    }
  `);

  const { language } = useContext(I18nextContext);

  const getPhoneNumber = (phone) => {
    if (language === 'kr') {
      return phone;
    } else {
      return `+82-${phone.toString().substring(1)}`;
    }
  };

  const getIcon = (name) => {
    switch (name) {
      case 'envelope':
        return Envelope;
      case 'phone':
        return Phone;
      case 'map':
        return Map;
      default:
        return Envelope;
    }
  };

  const PhoneSlice = ({ number }) => {
    const phone = number.replaceAll('-', '');

    return (
      <a href={`tel:${phone}`}>
        <Trans>{getPhoneNumber(number)}</Trans>
      </a>
    );
  };

  return (
    <div className='contact-us' id='contact_us'>
      <ContactUsForm />
      <div className='row info-wrapper no-gutters px-0'>
        <h1 className='text-start'>{t('READY_TO_BOARD')}</h1>

        <div>
          <ul className='row no-gutters'>
            {data.allContactJson.edges.map(({ node }) => (
              <li key={node.name}>
                <div>
                  <img alt={node.name} src={getIcon(node.icon)} />
                </div>
                <div className='text-wrapper'>
                  {node.name === 'phone' ? (
                    <PhoneSlice number={node.value} />
                  ) : (
                    <Trans>{node.value}</Trans>
                  )}
                </div>
              </li>
            ))}
          </ul>

          <Social />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
