import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Menu from './Menu';
import Hamburger from './Hamburger';
import MenuMobile from './MenuMobile';

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      configJson {
        logo {
          mobile
          desktop
          desktop_height
          mobile_height
          alt
        }
      }
    }
  `);

  const { alt, desktop, desktop_height } = data.configJson.logo;

  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <div className='header fixed-top bg-white'>
      <div className='container no-gutters'>
        <div className='logo'>
          <Link to='/'>
            <img
              alt={alt}
              src={desktop}
              height={desktop_height}
              style={{ maxHeight: desktop_height }}
            />
          </Link>
        </div>
        <Menu />

        <div className='mobile-menu-buttons'>
          <Hamburger toggleMenu={toggleMenu} />
        </div>
        <MenuMobile active={menuActive} />
      </div>
    </div>
  );
};

export default Header;
