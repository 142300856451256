import React, { useContext } from 'react';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import ImgKr from '../../static/images/assistance/assistance-mobile-kr.png';
import ImgEn from '../../static/images/assistance/assistance-mobile-en.png';

const Assistance = (props) => {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);

  return (
    <section className='assistance'>
      <div className='section-between' lang={language}>
        <div className='bg-img-wrapper'>
          <article className='image-wrapper'>
            <img alt='assistance' src={language === 'en' ? ImgEn : ImgKr} />
          </article>

          <article className='col-12 col-lg-6 order-2 order-lg-1 text-wrapper'>
            <div className='text-center text-lg-left'>
              <h1>{t('SECTION4_HEADING')}</h1>
              <p>{t('SECTION4_BODY')}</p>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Assistance;
