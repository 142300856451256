import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Img from '../../static/images/virtualstore/virtual-store.png';

const VirtualStore = (props) => {
  const { t } = useTranslation();

  return (
    <section className='virtual-store'>
      <div className='no-container'>
        <div className='row no-gutters'>
          <article className='col-12 col-lg-6 p-0'>
            <div className='col order-1 img-wrapper p-0'>
              <img alt={t('IMG_ALT_VIRTUAL_STORE')} src={Img} />
            </div>
          </article>
          <article className='col-12 col-lg-6 text-wrapper p-0'>
            <div className='text-center text-lg-left'>
              <h1>{t('SECTION5_HEADING')}</h1>
              <p>{t('SECTION5_BODY')}</p>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default VirtualStore;
