import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';

const ContactUsForm = () => {
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEamilError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        process.env.GATSBY_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.GATSBY_EMAILJS_PUBLIC_KEY
      )
      .then((response) => {
        setEmailSent(true);
        setEamilError(false);
      })
      .catch((e) => {
        setEamilError(true);
      });
  };

  return (
    <div className='row justify-lg-content-start form-wrapper no-gutters'>
      {emailSent ? (
        <div className='text-center after-form-submit'>
          <img alt='check' src='/images/contact-us/checkIcon.svg' />
          <h1>{t('THANK_YOU')}</h1>
          <h3>{t('THANK_YOU_MSG')}</h3>
        </div>
      ) : (
        <form onSubmit={sendEmail} className='contact-us-form'>
          <h1 className='text-center'>{t('READY_TO_BOARD')}</h1>

          <div className='form-group desktop-alignment'>
            <div className='form-row'>
              <label htmlFor='full_name'>{t('FULL_NAME')}*</label>
              <input
                type='text'
                className='form-control'
                id='full_name'
                name='full_name'
                placeholder='John Doe'
                required
              />
            </div>
            <div className='form-row '>
              <label htmlFor='company'>{t('COMPANY_NAME')}</label>
              <input
                type='text'
                className='form-control'
                id='company'
                name='company'
                placeholder='ACME, Inc.'
              />
            </div>
          </div>

          <div className='form-row'>
            <label htmlFor='email'>{t('EMAIL')}*</label>
            <input
              type='email'
              className='form-control'
              id='email'
              name='email'
              placeholder='johndoe@example.com'
              required
            />
          </div>
          <div className='form-row'>
            <label htmlFor='phone_number'>{t('PHONE_NUMBER')}</label>
            <input
              type='text'
              className='form-control'
              id='phone_number'
              name='phone_number'
              placeholder='4082223333'
            />
          </div>
          <div className='form-row'>
            <label htmlFor='message'>{t('HOW_CAN_WE_HELP')}*</label>
            <textarea
              className='form-control'
              id='message'
              name='message'
              rows='8'
              required
            />
          </div>

          <div className='form-error'>
            {emailError && <span>{t('SUBMIT_ERROR_MSG')}</span>}
          </div>

          <div className='form-row button-wrapper'>
            <button type='submit' className='btn btn-primary-button button'>
              {t('SUBMIT')}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactUsForm;
