import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Trans, Link } from 'gatsby-plugin-react-i18next';
import LanguageSelect from './LanguageSelect';

const Menu = (props) => {
  const data = useStaticQuery(graphql`
    query MainMenuQuery {
      allMainMenuJson {
        edges {
          node {
            name
            url
            weight
          }
        }
      }
    }
  `);

  return (
    <div id='main-menu' className='main-menu'>
      <ul>
        {data.allMainMenuJson.edges.map(({ node }) => (
          <li key={node.name}>
            <Link to={node.url} activeClassName='active'>
              <Trans>{node.name}</Trans>
            </Link>
          </li>
        ))}
        <li className='language-select-wrapper'>
          <LanguageSelect />
        </li>
      </ul>
    </div>
  );
};

export default Menu;
