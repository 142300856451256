import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

const Intro = (props) => {
  const { t } = useTranslation();

  return (
    <div className='intro'>
      <div className='container'>
        <div className='inner-container'>
          <div className={`${'heading-wrapper'}`}>
            <h1>{t('INTRO_HEADING')}</h1>
            <h1>{t('INTRO_SUB_HEADING')}</h1>
          </div>
          <div className='button-wrapper'>
            <Link to='/#product'>
              <button type='button' className='btn btn-primary-button button'>
                {t('PEEK_INSIDE')}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
