import { useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import React from 'react';

const Social = (props) => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      allSocialJson {
        edges {
          node {
            name
            image
            link
            text
          }
        }
      }
    }
  `);
  const { t } = useTranslation();

  return (
    <ul className='social row no-gutters'>
      {data.allSocialJson.edges.map(({ node }) => (
        <a key={node.name} href={node.link} target='blank'>
          <li>
            <div>
              <img src={node.image} title={node.name} alt={node.name} />
            </div>
            <div>{t(node.text)}</div>
          </li>
        </a>
      ))}
    </ul>
  );
};

export default Social;
