import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

const LanguageSelect = () => {
  const { changeLanguage } = useI18next();

  return (
    <div className='language-select text-muted'>
      <span>
        <a
          href='#!'
          aria-label='change-to-english'
          tabIndex={0}
          role='button'
          onClick={() => changeLanguage('en')}
        >
          EN
        </a>
      </span>

      <span>
        <a
          href='#!'
          aria-label='change-to-korean'
          tabIndex={0}
          role='button'
          onClick={() => changeLanguage('kr')}
        >
          한국어
        </a>
      </span>
    </div>
  );
};

export default LanguageSelect;
