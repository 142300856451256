import React, { useContext } from 'react';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import ImgKr from '../../static/images/all-in-one/all-in-one-kr.png';
import ImgEn from '../../static/images/all-in-one/all-in-one-en.png';

const AllInOne = (props) => {
  const { t } = useTranslation();

  const { language } = useContext(I18nextContext);

  return (
    <section className='all-in-one'>
      <div className='section-between'>
        <div className='bg-img-wrapper'>
          <article className='image-wrapper order-1 order-lg-2'>
            <img alt='allinone' src={language === 'en' ? ImgEn : ImgKr} />
          </article>

          <article className='order-2 order-lg-1 text-wrapper'>
            <div className='text-center text-lg-left'>
              <h1>{t('SECTION2_HEADING')}</h1>
              <p>{t('SECTION2_BODY')}</p>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default AllInOne;
